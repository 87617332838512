<script>
import { Notification } from 'epmc-patterns/components/v2'
import Feedback from '@/templates/Feedback'

export default {
  metaInfo() {
    return {
      title: 'Feedback - Europe PMC',
    }
  },
  components: { Notification, Feedback },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sent: false,
    }
  },
  methods: {
    close(e) {
      if (e && e.success) {
        this.sent = true
      } else {
        window.close()
      }
    },
  },
}
</script>
<template>
  <div class="grid-row">
    <div class="col-16">
      <h1>Submit your feedback</h1>
      <template v-if="sent">
        <notification notification-style="confirmation" class="notification">
          Thank you for your feedback. This will help us to continuously improve
          the site for you.
        </notification>
        <input
          v-if="url"
          type="button"
          class="secondary"
          value="Close"
          @click.prevent="close"
        />
      </template>
      <feedback v-else :page-url="url" @close="close" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.grid-row {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  .notification {
    margin: ($base-unit * 4) 0;
  }
}
</style>
